import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";

export default function AlertBanner({ content }) {
  const { frontmatter, html } = content;
  const { title, description } = frontmatter || {};
  const [show, setShow] = useState(true);

  return (
    <>
    {
      show && 
      <AlertComponent className="m-4" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{title}</Alert.Heading>
        <p>
          {description}
        </p>
        <hr />
        <p className="mb-0">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </p>
      </AlertComponent>
    }
    {
      !show &&
      <SimpleAlertComponent className="m-4" onClick={() => setShow(true)}>
        Show alert
      </SimpleAlertComponent>
    }
    </>
  );
}

const AlertComponent = styled(Alert)`
  color: #070199;
  background-color: #f3f3f3;
`;

const SimpleAlertComponent = styled(Alert)`
  cursor: pointer;
  color: #070199;
  background-color: #f3f3f3;
`