import React from "react";
import { Link, navigateTo } from "gatsby";
import * as ReactBootstrap from "react-bootstrap";
import styled from "styled-components";

export default function Card({ info }) {
  const { title, description, image } = info.node.frontmatter || info.node.info;
  return (
    <ReactBootstrap.Card className="d-flex fixed-height-card">
      <CardBody>
        <CardTitle onClick={() => navigateTo(info.node.fields.slug)}>
          <Link className="text-dark" to={info.node.fields.slug}>
            {title}{" "}
          </Link>
          <CardBadge>
            <i className="fas fa-link" />
          </CardBadge>
        </CardTitle>
        
        <CardTextCollapsed>
          {description} 
        </CardTextCollapsed>
      </CardBody>
    </ReactBootstrap.Card>
  );
}

const CardTitle = styled(ReactBootstrap.Card.Title)`
  cursor: pointer;
`;

const CardBody = styled(ReactBootstrap.Card.Body)` *
  height: 400px;
  max-height: 400px;
`;

const CardTextCollapsed = styled(ReactBootstrap.Card.Text)`
  max-height: 150px;
  height: 150px;
  overflow-y: scroll;
`;

const CardBadge = styled(ReactBootstrap.Badge).attrs({
  variant: "primary"
})`
  position: absolute;
  top: 0;
  right: 0;
`;

const ImageRow = styled(ReactBootstrap.Row)`
  width: 100%;
  height: 150px;
  margin: 0;
`;

const CardImage = styled(ReactBootstrap.Image)`
  width: inherit;
  height: inherit;
`;
