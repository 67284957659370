import React from "react";
import * as ReactBootstrap from "react-bootstrap";
import Card from "./card";

export default function CardList({ cards, config }) {
  return (
    <ReactBootstrap.Row>
      {cards.map(card => (
        <ReactBootstrap.Col xs={4} key={card.node.fields.slug} className="my-2">
          {<Card info={card} config={config} />}
        </ReactBootstrap.Col>
      ))}
    </ReactBootstrap.Row>
  );
}
