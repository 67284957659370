import React from "react";
import { graphql } from "gatsby";
import _ from 'lodash';
import * as ReactBootstrap from "react-bootstrap";
import Layout from "../components/layout";
import CardList from "../components/documentation/card-list";
import AlertBanner from '../components/documentation/alertBanner';
import { getChildrenForGivenParent, getAllApiReferences } from "../utils/util";

export default function Documentation({
  data: { content, documents, apiReferencesYaml, alertBanners },
  pageContext: { slug }
}) {
  /*
   in order to show a Documentation template followed by a child Documentation template the correct folder name
    should be extracted from the slug
    example: "/beta/beta-tutorials/"
    folderName should be "beta-tutorials" in order to call getChildrenForGivenParent()
  */
  let folderHierarchy = slug.split("/").filter(word => word.length > 1);
  let folderName = folderHierarchy[folderHierarchy.length - 1] || undefined;
  const folderCards = getChildrenForGivenParent(folderName, documents) || [];
  const apiForAllFolders = null;  //  getAllApiReferences(folderCards, apiReferencesYaml);
  const alertContent = (_.find(alertBanners.edges, (alertBanner) => {
    return alertBanner.node.frontmatter.id === content.frontmatter.alert_banner
  }) || {}).node || {};
  return (
    <>
      <Layout
        title={content.frontmatter.title}
        description={content.frontmatter.description}
        slug={content.fields.slug}
        isCurrentNodeAParent={folderCards.length > 0}
      >
        { !_.isEmpty(alertContent) && <AlertBanner content={alertContent} /> }
        <ReactBootstrap.Container className="py-4">
          <div dangerouslySetInnerHTML={{ __html: content.html }} />
          {// In case of no APIs, folders are displayed as cards
          folderCards && !apiForAllFolders && content.frontmatter.show_cards && <CardList cards={folderCards} />}
          {// APIs are listed based on folder structure as cards
          folderCards &&
            apiForAllFolders &&
            folderCards.map(edge => {
              return (
                <div key={edge.node.fields.slug}>
                  <h3>{edge.node.frontmatter.title}</h3>
                  <CardList
                    cards={apiForAllFolders[edge.node.fields.current]}
                  />
                </div>
              );
            })}
        </ReactBootstrap.Container>
      </Layout>
    </>
  );
}

export const pageQuery = graphql`
  query Documentation($slug: String!) {
    content: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        alert_banner
        show_cards
      }
      fields {
        slug
        current
        parent
        root
      }
      html
    }
    documents: allMarkdownRemark(
      filter: {
        frontmatter: { type: { in: ["documentation", "tree_layout"] } }
      }
      sort: {
        fields: [frontmatter___page_order, fields___slug]
        order: [DESC, ASC]
      }
    ) {
      edges {
        node {
          fields {
            slug
            current
            parent
            root
          }
          frontmatter {
            title
            description
            type
            # image {
            #   publicURL
            # }
          }
          headings {
            value
            depth
          }
        }
      }
    }
    alertBanners: allMarkdownRemark(
      filter: {
        frontmatter: { type: { in: ["alert"] } }
      }
    ) {
      edges {
        node {
          frontmatter {
            # id
            title
            description
          }
          html
        }
      }
    }
    # apiReferencesYaml: allYaml(
    #   sort: { fields: [info___page_order, fields___slug], order: [DESC, ASC] }
    # ) {
    #   edges {
    #     node {
    #       id
    #       info {
    #         description
    #         title
    #       }
    #       fields {
    #         slug
    #         current
    #         parent
    #         root
    #         jsonContent
    #       }
    #     }
    #   }
    # }
  }
`;
